#footer {
  background-color: #ffffff;
  height: 70px;
  width: 100%;
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  z-index: 998;

  .links-container {
    font-size: 14px;
    color: #29c086;
    width: 100%;

    div {
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: center;
    }
  }

  a {
    white-space: nowrap;
    font-size: 15px;
  }

  > span {
    font-size: 12px;
  }
}
